import * as React from 'react'

const Footer = () => {
    return (
        <div className="py-8 bg-white-smoke">
            <footer className="sf-container text-center">
                © Scrum Facilitators 2021
            </footer>
        </div>
    )
}

export default Footer

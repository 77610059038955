import * as React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'

// The information hub does not exist in the CMS yet, so
// we'll use mock data for now
const informationHubLoremIpsumHeadings = [
    {
        name: 'Lorem ipsum dolar sit'
    },
    {
        name: 'Lorem ipsum dolar sit'
    },
    {
        name: 'Lorem ipsum dolar'
    },
    {
        name: 'Lorem ipsum dolar sit'
    },
    {
        name: 'Lorem ipsum'
    },
    {
        name: 'Lorem ipsum dolar sit amet'
    },
    {
        name: 'Lorem ipsum dolar sit amet'
    }
];

interface InformationHubCategory {
    name: string
    pages: {
        name: string
        headings: {
            name: string
        }[]
    }[]
}

let informationHubMockData: InformationHubCategory[] = [
    {
        name: 'Introduction',
        pages: [
            {
                name: 'What is Scrum?',
                headings: informationHubLoremIpsumHeadings.slice(0, 3)
            },
            {
                name: 'Scrum Framework',
                headings: informationHubLoremIpsumHeadings.slice(0, 2)
            },
            {
                name: 'Empiricism',
                headings: informationHubLoremIpsumHeadings.slice(0, 1)
            },
            {
                name: 'Scrum Values',
                headings: informationHubLoremIpsumHeadings.slice(0, 3)
            },
        ]
    },
    {
        name: 'The Scrum Team',
        pages: [
            {
                name: 'Scrum Team',
                headings: informationHubLoremIpsumHeadings.slice(0, 1)
            },
            {
                name: 'Scrum Master',
                headings: informationHubLoremIpsumHeadings.slice(0, 3)
            },
            {
                name: 'Product Owner',
                headings: informationHubLoremIpsumHeadings.slice(0, 4)
            },
            {
                name: 'Developer',
                headings: informationHubLoremIpsumHeadings.slice(0, 2)
            }
        ]
    },
    {
        name: 'Scrum Events',
        pages: [
            {
                name: 'Sprint',
                headings: informationHubLoremIpsumHeadings.slice(0, 2)
            },
            {
                name: 'Sprint Planning',
                headings: informationHubLoremIpsumHeadings.slice(0, 3)
            },
            {
                name: 'Daily Scrum',
                headings: informationHubLoremIpsumHeadings.slice(0, 1)
            },
            {
                name: 'Sprint Review',
                headings: informationHubLoremIpsumHeadings.slice(0, 3)
            },
            {
                name: 'Sprint Retrospective',
                headings: informationHubLoremIpsumHeadings.slice(0, 4)
            }
        ]
    },
    {
        name: 'Scrum Artifacts',
        pages: [
            {
                name: 'Product Backlog',
                headings: [
                    {
                        name: 'Product Goal'
                    },
                    {
                        name: 'Refinement'
                    }
                ]
            },
            {
                name: 'Sprint Backlog',
                headings: [
                    { name: 'Sprint Goal' }
                ]
            },
            {
                name: 'Increment',
                headings: []
            },
            {
                name: 'Transparency',
                headings: [
                    {
                        name: 'Definition of Done'
                    }
                ]
            }
        ]
    },
]

const NavbarInformationHub = () => {
    return (
        <div className="flex justify-center space-x-16 flex-grow">
            {informationHubMockData.map(category => (
                <div key={category.name}>
                    <span className="font-semibold mb-1">{category.name}</span>
                    <div className="flex flex-col">
                        {category.pages.map(page => (
                            <div key={page.name} className="flex flex-col">
                                <Link className="hover:text-sf-orange-darker mb-1" to="">{page.name}</Link>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default NavbarInformationHub

// This is a more advanced information navbar component than the other one
// but it might look weird if there isn't enough content. So we'll keep this
// just for later.

// const NavbarInformationHub = () => {
//   const [category, setCategory] = React.useState(data[0]);

//   return (
//     <div className="flex space-x-8">

//       <div className="whitespace-nowrap">
//         {data.map(category => (
//           <div onClick={() => setCategory(category)} onMouseEnter={() => setCategory(category)} className="flex w-full items-center text-right cursor-pointer p-4 hover:bg-gray-100 rounded">
//             <span>{category.name}</span>
//             <span className="flex-grow ml-4 material-icons">
//               chevron_right
//             </span>
//           </div>
//         ))}
//       </div>

//       <div className="flex-grow">
//         <div className="flex space-x-8">
//           {category.pages.map(page => (
//             <div className={classNames("flex flex-col", { "mb-1": page.headings.length > 0 })} key={page.name}>
//               <Link className="font-semibold mb-1 hover:text-sf-orange-darker" to={""}>{page.name}</Link>
//               {page.headings.map(heading => (
//                 <Link key={heading.name} className="hover:text-sf-orange-darker" to={""}>{heading.name}</Link>
//               ))}
//             </div>
//           ))}
//         </div>
//       </div>

//     </div>
//   )
// }

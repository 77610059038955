import * as React from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'


const NavbarMobile = () => {
    const data = useStaticQuery(graphql`
    query MobileNavbarQuery {
      allStrapiNavbar {
        nodes {
          locale
          informationHub
          courses
          meetups
          products
          sfFoundation
          aboutUs
        }
      } 
      allStrapiCourse {
        nodes {
          locale
          title
          subtitle
          token {
            localFile {
              publicURL
            }
          }
        }
      }
    }`
    )

    // Gatsby doesn't support the usage of variables in static GraphQL queries.
    // We need to get the content matching the selected language a different way then.
    const { changeLanguage, language } = useI18next();
    const links = data.allStrapiNavbar.nodes.find(x => x.locale == language);
    const courses = data.allStrapiCourse.nodes.filter(x => x.locale == language);

    return (
        <div className="w-full bg-white md:hidden">
            <div className="sf-container flex items-center justify-between">
                <div className="block lg:hidden">
                    <button id="nav-toggle" className="flex items-center px-3 py-2  rounded text-gray-500  hover:text-white hover:border-white">
                        <svg className="fill-current h-10 w-10" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                    </button>
                </div>

                <div className="flex-shrink-0 text-white mr-6">
                    <Link to="/">
                        <img className="h-14 py-2 box-content" src="/images/logo.png" />
                    </Link>
                </div>

                <div className="h-full cursor-pointer flex items-center space-x-1.5 text-muted-100 hover:text-muted-200">
                    <span className="material-icons-outlined" style={{ fontSize: 22 }}>
                        translate
                    </span>
                    <span>English</span>
                </div>

                {/* <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0" id="nav-content">
                    <ul className="list-reset lg:flex justify-end flex-1 items-center">
                        <li className="mr-3">
                            <a className="inline-block py-2 px-4 text-white no-underline" href="#">Active</a>
                        </li>
                        <li className="mr-3">
                            <a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
                        </li>
                        <li className="mr-3">
                            <a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
                        </li>
                        <li className="mr-3">
                            <a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
                        </li>
                    </ul>
                </div> */}
            </div>
        </div >
    )
}

export default NavbarMobile

import * as React from 'react'
import classNames from 'classnames'

const Dropdown = ({ data, selected, display, select, onSelect, getKey, alignRight }) => {
    const ref = React.useRef<HTMLDivElement>();
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(selected);

    // We need to hide the collapsible part of the dropdown when there is a click outside the component
    React.useEffect(() => {
        function handleClick(e) {
            setIsOpen(ref.current.contains(e.target))
        }

        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick);
        }
    }, []);

    function handleSelect(item) {
        setSelectedItem(item);
        onSelect(item);
    }

    return (
        <div ref={ref} className="relative">
            {display(selectedItem)}
            <div className={classNames("absolute mt-1 flex flex-col bg-white p-2 shadow rounded space-y-0.5 whitespace-nowrap z-50", { "hidden": !isOpen, "right-0": alignRight })}>
                {data.map(item => (
                    <div key={getKey(item)} className="select-none hover:bg-gray-100 p-2 rounded" onClick={() => handleSelect(item)}>
                        <div className="flex items-center space-x-2">
                            <input className="pointer-events-none" type="radio" checked={getKey(selectedItem) === getKey(item)} />
                            <div>
                                {select(item)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

Dropdown.defaultProps = {
    alignRight: false
}

export default Dropdown

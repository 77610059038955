import * as React from 'react'
import Navbar from './navbar'
import NavbarMobile from './navbar-mobile'
import Footer from './footer'
import { Helmet } from 'gatsby-plugin-react-i18next'
import classNames from 'classnames'

const Layout = ({ title, children, shouldApplyContainer }) => {
    return (
        <div className="w-full min-h-full flex flex-col">
            <Helmet>
                <title>{title} - Scrum Facilitators</title>
            </Helmet>
            <div className="flex-grow flex flex-col">
                <Navbar />
                <NavbarMobile />
                <main className={classNames("flex-grow", { "sf-container my-8": shouldApplyContainer })}>
                    {children}
                </main>
                <Footer />
            </div>
        </div>
    )
}

Layout.defaultProps = {
    shouldApplyContainer: true
}

export default Layout

import * as React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import Dropdown from '../dropdown'

const LanguageSelector = () => {
    // Define the languages in which the content is available
    const { changeLanguage, language } = useI18next();
    const languages = [
        {
            locale: 'en',
            text: 'English'
        },
        {
            locale: 'nl',
            text: 'Nederlands'
        }
    ];
    const selected = languages.find(x => x.locale === language);

    return (
        <Dropdown
            data={languages}
            display={language => (
                <div className="h-full cursor-pointer flex items-center space-x-1.5 text-muted-100 hover:text-muted-200 select-none">
                    <span className="material-icons-outlined" style={{ fontSize: 22 }}>
                        translate
                    </span>
                    <span>{language.locale.toUpperCase()}</span>
                </div>
            )}
            select={language => (
                <div>{language.text}</div>
            )}
            onSelect={language => changeLanguage(language.locale)}
            selected={selected}
            getKey={language => language.locale}
            alignRight={true}
        />
    )
}

export default LanguageSelector

import * as React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import Token from '../token'
import { chunk } from 'lodash'
import { Course } from '../../models/course'

const NavbarCourses = ({ subtitle, courses }: { subtitle: string, courses: Course[] }) => {
    return (
        <div className="space-y-8">
            <div className="text-muted-200">
                {subtitle}
            </div>
            <div className="flex justify-between">
                {/* We create groups of 4 elements. The courses we get are ordered, so this will group the courses like in the design. */}
                {chunk(courses, 4).map(chunk =>
                    <div key={chunk.map(x => x.slug).join('-')} className="space-y-5">
                        {chunk.map(course => (
                            <Link key={course.slug} className="block" to={`/course/${course.slug}`}>
                                <Token course={course} />
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default NavbarCourses

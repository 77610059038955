import * as React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'

const NavbarLink = ({ item, setIndex, isOpen }) => {
    // We show the orange border when the element hovered over or the item its submenu is shown
    const className = `h-full flex items-center border-b-4 hover:border-sf-orange cursor-pointer select-none ${isOpen ? 'border-sf-orange' : 'border-transparent'}`;

    return (
        // We need to decide the component based on whether the item has a submenu or not
        <div className="h-full">
            {item.to &&
                <Link to={item.to} className={className}>
                    {item.text}
                </Link>
            }
            {!item.to &&
                // Hide the submenu if the user clicks on the same element again, otherwise open it
                <button onClick={() => isOpen ? setIndex(null) : setIndex(item.index)} className={className}>
                    {item.text}
                </button>
            }
        </div>
    )
}

export default NavbarLink

import * as React from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import NavbarLink from './navbar/link'
import NavbarCourses from './navbar/courses'
import NavbarInformationHub from './navbar/information-hub'
import LanguageSelector from './navbar/language-selector'

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      allStrapiNavbar {
        nodes {
          locale
          informationHub
          courses
          coursesSubtitle
          meetups
          products
          sfFoundation
          aboutUs
        }
      } 
      allStrapiCourse(sort: {fields: order}) {
        nodes {
          locale
          title
          subtitle
          slug
          token {
            localFile {
              publicURL
            }
          }
        }
      }
    }`
  )

  // Gatsby doesn't support the usage of variables in static GraphQL queries.
  // We need to get the content matching the selected language a different way then.
  const { language } = useI18next();
  const links = data.allStrapiNavbar.nodes.find(x => x.locale == language);
  const courses = data.allStrapiCourse.nodes.filter(x => x.locale == language);

  // Define all the places a user can navigate to / which submenus they can open
  interface NavbarItem {
    text: string
    to?: string
    submenu?: JSX.Element
    index?: number
  }

  const navbar: NavbarItem[] = [
    {
      text: links.informationHub,
      submenu: (<NavbarInformationHub />)
    },
    {
      text: links.courses,
      submenu: (<NavbarCourses subtitle={links.coursesSubtitle} courses={courses} />)
    },
    {
      to: '/meetups',
      text: links.meetups
    },
    {
      to: '/products',
      text: links.products
    },
    {
      to: '/sf-foundation',
      text: links.sfFoundation
    },
    {
      to: '/about',
      text: links.aboutUs
    }
  ];
  navbar.forEach((item, i) => item.index = i);

  // We're using the index of the element here because for whatever reason
  // comparing the references of the item doesn't work, so this is the most convenient option
  // because we can making use of indexing.
  const [index, setIndex] = React.useState<number>();

  // Disable scrolling depending on whether or a submenu is shown 
  React.useEffect(() => {
    window.document.body.style.overflow = index != null ? 'hidden' : 'auto';
  }, [index])

  return (
    <div className="relative w-full bg-white hidden md:block">

      {/* Collapsible element that holds the submenu  */}
      {index != null &&
        <div className="absolute bg-white w-full top-full py-8 z-40" >
          <div className="sf-container">
            {navbar[index].submenu}
          </div>
        </div>
      }

      {/* Darkening overlay */}
      {index != null &&
        <div onClick={() => setIndex(null)} className="absolute bg-black bg-opacity-80 w-full top-full h-screen z-30" />
      }

      {/* Navbar itself */}
      <div className="sf-container flex justify-between">
        <Link to="/">
          <img className="h-14 py-2 box-content" src="/images/logo.png" />
        </Link>
        <div className="flex items-center space-x-4 lg:space-x-8">
          {navbar.map(item => (
            <NavbarLink key={item.index} isOpen={item.index === index} item={item} setIndex={setIndex} />
          ))}

          <LanguageSelector />
        </div>
      </div>

    </div>
  )
}

export default Navbar

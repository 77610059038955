import * as React from 'react'
import { Course } from '../models/course'

const Token = ({ course }: { course: Course }) => (
    <div className="flex items-center space-x-3">
        <div className="w-12 h-12">
            <img src={course.token.localFile.publicURL} />
        </div>
        <div className="flex-1 flex-col justify-center">
            <div>{course.title}</div>
            {course.subtitle &&
                <div className="text-sm text-muted-200">{course.subtitle}</div>
            }
        </div>
    </div>
)

export default Token
